import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Hooper, Slide, Pagination as HooperPagination, Navigation as HooperNavigation } from 'hooper';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import { Asset } from '@/store/models/asset';

@Component({
  components: {
    Hooper,
    Slide,
    LazyImage,
    HooperPagination,
    HooperNavigation,
  },
})
export default class PropertyGallery extends Vue {
  @Prop({ default: (): boolean => false }) userLoggedIn!: boolean;

  @Prop({ required: true })
  images!: [string, string][] | undefined;

  @Prop({ default: (): undefined => undefined })
  asset!: Asset;

  @Watch('images.length')
  onImagesLengthChange(newLength: number, oldLength: number): void {
    if (newLength !== oldLength && this.$refs.gallery) {
      // @ts-ignore
      this.$refs.gallery.restart();
    }
  }

  slideTo(number: number): void {
    // @ts-ignore
    this.$refs.gallery.slideTo(number);
  }
}

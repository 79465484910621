import { Vue, Component, Prop } from 'vue-property-decorator';
import { Asset } from '@/store/models/asset';
import ProgressBar from '@/components/common/progress-bar/ProgressBar.vue';

@Component({
  components: {
    ProgressBar,
  },
})
export default class PropertyContent extends Vue {
  @Prop({ default: (): null => null }) asset!: Asset;
  @Prop({ default: (): boolean => false }) userLoggedIn!: boolean;
}

import to from 'await-to-js';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
/* eslint-disable import/no-unresolved */
import { Asset, AssetAddress } from '@/store/models/asset';
// @ts-ignore
import {} from '@types/google.maps';

type Location = { lat: number, lng: number };

declare module 'vue/types/vue' {
  interface Vue {
    $gmapApiPromiseLazy: () => Promise<any>,
  }
}

@Component({})
export default class PropertyMap extends Vue {
  @Prop({ default: (): null => null }) asset!: Asset;
  @Ref() map: any;

  locations: Location[] = [];
  mapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: true,
  };

  mounted(): void {
    if (this.asset) {
      this.initializeMap(this.asset);
    }
  }

  @Watch('asset')
  onAssetChange(newAsset: Asset): void {
    if (this.asset && this.asset.city) {
      this.initializeMap(newAsset);
    }
  }

  getAddressString(asset: Asset | AssetAddress): string {
    return asset ? `${asset.street || ''} ${asset.houseNumber || ''} ${asset.city || ''} ${asset.country || ''}`.trimLeft() : '';
  }

  async initializeMap(asset: Asset): Promise<any> {
    const [mapsApiError, mapsApi] = await to(this.$gmapApiPromiseLazy());
    if (mapsApiError || mapsApi === undefined) {
      return;
    }

    const geocoder = new mapsApi.maps.Geocoder() as google.maps.Geocoder;
    const bounds = new mapsApi.maps.LatLngBounds() as google.maps.LatLngBounds;

    const addressStrings: string[] = asset.addresses ?
      [...asset.addresses.map(this.getAddressString)] :
      [this.getAddressString(asset)];

    const locationsPromises = addressStrings.map((address): Promise<Location> => new Promise((resolve, reject): Promise<google.maps.GeocoderResponse> | null => {
      const unusedGeocoderPromise = geocoder.geocode({ address }, (results, status): void => {
        if (status === 'OK' && results) {
          const location = results[0].geometry.location;
          bounds.extend(location);
          resolve({ lat: location.lat(), lng: location.lng() });
        } else {
          reject(status);
        }
      });
      if (unusedGeocoderPromise === null) {
        return unusedGeocoderPromise;
      }
      unusedGeocoderPromise.catch((): void => {});
      return unusedGeocoderPromise;
    }));
    const [getLocationsError, getLocations] = await to(Promise.all(locationsPromises));
    if (getLocationsError || !getLocations) {
      console.log('error', getLocationsError || 'no locations'); // eslint-disable-line no-console
      this.locations = [];
      return;
    }
    this.locations = getLocations;
    if (this.locations.length > 1) {
      this.$nextTick((): void => {
        if (this.map) {
          this.map.$mapPromise.then((map): void => {
            map.fitBounds(bounds);
          });
        }
      });
    }
  }
}
